<template>
    <b-card>
        <b-row>
            <b-col md="2" sm="4" class="my-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"> </label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">

            </b-col>
            <b-col md="6" class="my-1">
                <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col> 
            <b-col cols="12">
                <b-table   hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                    :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                    <!-- <template #cell(avatar)="data">
                        <b-avatar :src="data.item.foto_profil" rounded="" size="60" /> 
                    </template> -->

                    <template #cell(nm_lengkap)="data">
                      <b-row style="background: #eeeded;" class="p-1">
                        <b-col md="2">
                            <b-avatar :src="data.item.foto_profil" rounded="" size="120" /> 
                        </b-col>
                        <b-col md="5"><small>Nama Lengkap</small><br>
                            <h6>{{ data.item.nm_lengkap }}</h6>
                            <small>Kelompok </small><br>
                       <h6>{{ data.item.nama_kelompok }}</h6> 
                       <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" block variant="warning"
                            v-if="data.item.status_klp  !='Non Aktif'">
                            <feather-icon icon="CheckSquareIcon" size="15" class="mr-1" /> {{ data.item.status_klp }}
                        </b-button>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" block variant="danger"
                            v-if="data.item.status_klp  =='Non Aktif'">
                            <feather-icon icon="AlertTriangleIcon" size="15" class="mr-1" /> {{ data.item.status_klp }}
                        </b-button>
                        </b-col>
                        <b-col md="5"><small>Jabatan </small><br>
                            <h6>{{ data.item.nama_jabatan }}</h6>
                            <small>Status Keanggotaan  </small><br>
                       <h6 class="">{{ data.item.status_klp }}</h6> 
                     

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.Edit-user size="sm" block
                            variant="primary" @click="editData(data.item.anggota_id )">
                            <feather-icon icon="AlignJustifyIcon" size="15" class="mr-1" /> Detail
                        </b-button>
                        </b-col>
                      </b-row>
                    </template>

                   
                </b-table>
            </b-col>

            <b-col cols="6">
                Total Anggota <span v-show="totalRows == 0 ">{{ items.length }}</span>
                <span v-show="totalRows != 0 "> {{ totalRows }}</span> 
               
            </b-col>
            <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right"
                    size="sm" class="my-0" />
            </b-col>
        </b-row>
        <detailUser :user_data = user_data :rsKelompok = rsKelompok  @updateUser = updateUser></detailUser>
    </b-card>
</template>

<script>
    import detailUser from './detailUser.vue'
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BModal,
        VBModal
    } from 'bootstrap-vue'
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            detailUser,
            BModal,
            VBModal
        },
        mixins: [Base],
        data() {
            return {
                perPage: 3,
                pageOptions: [3, 10, 50, 100, 1000],
                totalRows: 0,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [ 
                    {
                        key: 'nm_lengkap',
                        label: '',
                    },  

                ],
                items: [],
                user_data : {},
                rsKelompok : []

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            this.cek_token();
            // Set the initial number of items
            const self = this;
            self.totalRows = self.items.length
            self.loadData();
            self.kelompok();
        },
        methods: {
            async kelompok() {
                    const self = this;
                    await axios({
                        method: 'PUT',
                        url: '/api/manajemen/anggota/kelompok',
                        data: {
                          
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.rsKelompok = response.data.result;
                        //self.rs_level = response.data.result;
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },
            async editData(anggota_id) {                
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/manajemen/anggota/edit',
                        data: {
                            anggota_id : anggota_id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.user_data = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async updateUser(value) {
                    const self = this;
                    await axios({
                        method: 'POST',
                        url: '/api/manajemen/anggota/update',
                        data: {
                            anggota_id : value.anggota_id,
                            id_kelompok : value.id_kelompok.id_kelompok,
                            status : value.status
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.notification('Info', "", response.data.message);
                        self.loadData();
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },
            async loadData() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/manajemen/anggota/load',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
    }
</script>