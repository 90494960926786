<template>
    <div class="center">
        <b-modal id="Edit-user" hide-footer no-close-on-backdrop content-class="shadow" title="Kelola Anggota"
            ok-title="Accept"> 
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col md="12">
                            <b-form-group>
                                <label>Nama Lengkap</label>
                                <b-form-input v-model="userdata.nm_lengkap" placeholder="Nama Lengkap" disabled="" />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group>
                                <label>NIK</label>
                                <b-form-input v-model="userdata.nik" placeholder="NIK" disabled="" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <label>Jabatan</label>
                            <b-form-group>
                                <b-form-input v-model="userdata.id_jabatan" placeholder="NIK" disabled="" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <label>Kelompok</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" name="Kelompok" rules="required">
                                    <v-select rules="required" :state="errors.length > 0 ? false:null"
                                        v-model="userdata.id_kelompok"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_kelompok"
                                        :options="rsKelompok" />
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <label>Status</label>
                            <b-row>
                                <b-col cols="6">
                                    <b-form-group>
                                        <b-form-radio v-model="userdata.status" name="some-radios" value="1">
                                            Aktif
                                        </b-form-radio>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group>
                                        <b-form-radio v-model="userdata.status" name="some-radios" value="0">
                                            Non Aktif
                                        </b-form-radio>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>

                        <hr>
                        <b-col cols="6">
                            <b-button class="mb-3 mt-1" variant="outline-primary" block type="submit"
                                @click.prevent="actSave">
                                <i class="fa fa-save mr-1"></i> Simpan
                            </b-button>
                        </b-col>
                        <b-col cols="6">
                            <b-button class="mb-3 mt-1" variant="outline-warning" block
                                @click="$bvModal.hide('Edit-user')">
                                <i class="fa fa-close mr-1"></i> Keluar</b-button>
                        </b-col>

                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required,
        email
    } from '@validations'
    import {
        BModal,
        BButton,
        VBModal,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BCardText,
        BFormRadio,
        BFormTextarea

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import Ripple from 'vue-ripple-directive'

    export default {
        props: {
            active: {},
            id_kelompok: {},
            user_data: {},
            rsKelompok :{}
        },
        data: () => ({
            required,
            email,
            levelList: []

        }),
        emits: ["updateUser"],
        components: {
            BModal,
            BButton,
            VBModal,
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BCardText,
            vSelect,
            BFormRadio,
            BFormTextarea
        },
        mounted() {

        }, 
        computed: {
            userdata: {
                get: function () {
                    return this.user_data;
                },
                set: function (newValue) {
                    return this.user_data = newValue
                }
            }
        },

        methods: {
            actSave() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit("updateUser", this.userdata);
                        this.userdata = {}
                        this.$root.$emit('bv::hide::modal', 'Edit-user');
                    }
                })
            },
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },

    }
</script>